import React, { useState } from 'react';
import Logo from '../tokplace-logo.svg';
import "./Login.scss"

function Login() {

  const [
    logedUser,
    setLogedUser
  ] = useState(false)

  // const {state} = useLocation();
 
  function handlerLogin(e){
    e.target.innerHTML = "<span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span> Loading..."
    e.preventDefault();

    fetch('http://api.tokplace/api/users/1')
      .then(res => res.json())
      .then(result => {
        sessionStorage.setItem('logedUser', JSON.stringify(result));
        setLogedUser(true);
        
      }).catch(erro=>{
        console.log(`Erro login: ${erro}`);
    });
  }

  if(logedUser === true){
    // return <Redirect to={state?.from || '/user'} />
    window.location.href='/user';
  }

  const email = {
    to:'sac@tokplace.com',
    subject:'Gostaria de me tornar um produtor de conteúdos na TokPlace.com',
    body:'Olá!\
    \n\nPara que você se torne um produtor de conteúdos na TokPlace.com, é necessário que você preencha o questionário abaixo com o seus dados e nos envie este e-mail.\
    \
    \n\n*** Não esqueça de anexar a foto do seu documento. ***\
    \
    \n\nNome:\nIdade:\nData de Nascimento:\nCPF:\nInstagram:@\nTipo de conteúdo:\
    \nEscolha uma e-mail para o qual deveremos enviar o seu convite para ser produtor(a):\
    \nDesejo tornar a TokPlace.com minha principal plataforma de vendas de assinaturas: ( )sim ( )não\
    \nFoto do documento (pode ser RG, CNH ou Passaporte)\
    \
    \n\nRealizaremos a avaliação e logo entraremos em contato. Fique ligado no seu Instagram. Podemos chamar por lá através do perfil @tokplace_oficial.\
    \
    \n\nObrigado.'
  };

  return (
    <div className="Login m-auto">
        <div className="card border-0 bg-transparent font-weight-light text-muted">
          <div className="card-body">
            <img src={Logo} className="mb-3 logo" alt="Logo" />
            <p className="mb-4 px-5 text-black-50 lead">Assista conteúdo exclusivo das suas produtoras favoritas</p>

            <a href={'https://app.tokplace.com/login'} className="btn btn-primary-pink w-75 py-3 mb-4">Entrar</a>

            <p>Ainda não tem conta? <a href="https://app.tokplace.com/signup" className="text-decoration-none font-weight-bold text-primary-pink">Seja membro</a> agora!</p>
            <p>Torne-se um <a href={`mailto:${email.to}?subject=${email.subject}&body=${encodeURI(email.body)}`} className="text-decoration-none font-weight-bold text-primary-pink">produtor de conteúdos</a> na TokPlace.</p>
          </div>
        </div>
    </div>
  );
}

export default Login;
