import React from 'react';
import Login from '../Login/Login';
import BgHome from './images/bg-top.png';
import Destaques from './Destaques';
import Top10 from './Top10';
import './Home.scss';

function Home(){
    return(
        <article className="Home">
            
            <section className="page-content">

                <div className="container-fluid mb-5">
                    <div className="row">

                        <div className="col-lg-6 text-center my-auto">
                            <Login />
                        </div>

                        <div className="col-lg-6 pr-0">
                            <img src={BgHome} className="img-fluid float-right" alt="celphone"/>
                        </div>
                        
                    </div>
                </div>

                <div className="queSomos">
                    <div className="container pb-5">
                        <div className="text-center col-lg-8 col-12 mx-auto">
                            <h1 className="text-primary-pink font-weight-light">Crie, inspire e conecte-se.</h1>
                            <p className="lead">A TokPlace é a plataforma ideal para fazer seu conteúdo render.</p>
                            <p className="lead">Além da rapidez e segurança, oferecemos os melhores recursos para você engajar e construir sua comunidade de seguidores.</p>
                            <p className="lead">Crie seu perfil e desenvolva conexões únicas com seus fãs.</p>
                        </div>
                    </div>
                </div>

                <Destaques />
                
                <Top10 />

            </section>

            <footer className="bg-primary-indigo">
                <div className="container">
                    <div className="d-flex align-items-lg-center justify-content-between flex-column flex-lg-row py-3 text-light">
                        <span>TokPlace, 2021. ©All Rights Reserved.</span>
                        <span>
                            <a href={'./termos-de-uso.html'} className={'text-light text-decoration-none'}>Termos de Uso</a> |
                            <a href={'./politica-privacidade.html'} className={'text-light text-decoration-none'}> Política de Privacidade</a> |
                            <a href={'./termos-de-compra.html'} className={'text-light text-decoration-none'}> Termos de compra</a>
                        </span>                        
                    </div>
                </div>
            </footer>

        </article>
    )
}

export default Home;