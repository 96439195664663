import {React, useEffect} from 'react';
import './Top10.scss';

function Top10(){
    useEffect(() => {
        const video = document.querySelector('#v-top10');
        video.autoplay = true;
        video.load();
    });

    const list = [
        {name:'@grazimourao',link:'https://app.tokplace.com/grazimourao'},
        {name:'@letsgab',link:'https://app.tokplace.com/letsgab'},
        {name:'@larissasumpani',link:'https://app.tokplace.com/larissasumpani'},
        {name:'@alynelary',link:'https://app.tokplace.com/alynelary'},
        {name:'@showmethemoney',link:'https://app.tokplace.com/showmethemoney'},
        {name:'@braganca',link:'https://app.tokplace.com/braganca'},
        {name:'@vicbernardi',link:'https://app.tokplace.com/vicbernardi'},
        {name:'@thelarih',link:'https://app.tokplace.com/thelarih'},
        {name:'@marilia',link:'https://app.tokplace.com/marilia'},
        {name:'@eujapanese',link:'https://app.tokplace.com/eujapanese'}
    ];
    const listTop = list.map((l,i) => {
        return <li key={`top10-${i}`} className="h4 text-primary-pink font-weight-light"><a className="text-primary-indigo text-decoration-none" href={l.link} >{l.name}</a></li>
    });
    return(
        <div className="Top10 ">
            <div className="container py-5">
                <div className="row flex-lg-row flex-column-reverse my-5">
                    <div className="col-lg-8 col-12 mx-auto">
                        <div className="mx-auto h-100 w-100 mx-sm-0 over-video overflow-hidden">
                            <video id="v-top10" loop controls={false} muted={true} playsInline={true} autoPlay={true}>
                                <source src={require('./images/tokplace-institucional.mp4').default} type="video/mp4" />Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top10;