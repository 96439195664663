import React from 'react';
import ReactDOM from 'react-dom';
import Web from './Web';
import './index.scss';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@fortawesome/fontawesome-free/js/all';

ReactDOM.render(
  <Web/>, document.getElementById('root')
);