import {React, useEffect} from 'react';
import ScrollOut from 'scroll-out';
// const ScrollOut = require('scroll-out');
import './Destaques.scss';

function Destaques(){
    
    useEffect(() => {
        ScrollOut({
        });
    });

    const itensDestaques = [
        { back:'Receba seu dinheiro em sua conta bancária, monitore seus ganhos e preveja quanto você vai receber.',front:{ico:'fas fa-credit-card',text:'Praticidade e facilidade no pagamento'} },
        { back:'Velocidade no carregamento de páginas e vídeos e navegação de alta performance.',front:{ico:'far fa-laugh-wink',text:'Experiência de usuário otimizada'} },
        { back:'Este site é um ambiente seguro, monitorado e sigiloso. Aqui, seus dados são sempre protegidos.',front:{ico:'fas fa-user-lock',text:'Segurança'} },
        { back:'Monitore suas métricas de engajamento.',front:{ico:'fas fa-chart-line',text:'Acompanhe sua performance'} },
    ];

    const Itens = itensDestaques.map((item, index) => {
        let side = index % 2 === 0 ? 'right':'left';
        return (
            <div key={`destaque-${index}`} className={`destaque-item ${side} col mb-4`} data-scroll>
                <div className="card h-100 text-center bg-indigo-light p-4 border-gray-light">
                    <div className="front mb-auto text-primary-blue">
                        <p className='display-4 my-2'><i className={item.front.ico}></i></p>
                        <h3 className='font-weight-light'>{item.front.text}</h3>
                        <span className="lead">{item.back}</span>
                    </div>
                </div>
            </div>
        )
    });

    return(
        <div className="Destaques border-top border-bottom border-gray-light">
            <div className='container py-5'>
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 my-5">
                    {Itens}
                </div>
            </div>
        </div>
    )
}

export default Destaques;