import React from 'react';
// import { ParallaxProvider } from 'react-scroll-parallax';
import { 
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import Home from './Home/Home';

function Web(){
 
  return(
    <Router>
      <Route exact path="/index.html">
        <Home />
      </Route>
    </Router>
  )
}

export default Web;